<template>
  <div class="TopSelector">
    <div class="container">
      <div class="breadcrumb">
        <my-breadcrumb>
          <my-breadcrumb-item to="/">{{$t('Goods.Pages.Home')}}</my-breadcrumb-item>
          <my-breadcrumb-item to="/" :active="true" :isDisabled="true"
            >{{$t('Goods.Pages.Gallerylist')}}</my-breadcrumb-item
          >
        </my-breadcrumb>
      </div>

      <!-- <div class="selector">
        <div class="choose">
          <div class="title"><span>{{$t('Cart.HaveChoose')}}：</span></div>

          <div class="chooseitem">
            <has-choose
              class="haschoose"
              v-for="item in randerIsChoose"
              :key="item.step"
              :title="item.detail.nature_name"
              :list="item.detail.chooseDetil"
              :step="item.step"
              @delOne="delOne"
            ></has-choose>
          </div>

          <div class="reset">
            <span @click="reset">{{$t('Common.Button.Reset')}}</span>
          </div>
        </div>
        <classify-item
          ref="classifyItem"
          v-for="item in NaturesList"
          :key="item.step"
          :ListItem="item"
        ></classify-item>
      </div> -->
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import ClassifyItem from "./TopSelectorChild/ClassifyItem";
import HasChoose from "./TopSelectorChild/HasChoose";
export default {
  name: "TopSelector",
  data() {
    return {};
  },
  created() {},
  computed: {
    // 为顶部渲染已经选择的项目
    randerIsChoose() {
      let arr = this.$store.state.gallerylist.gallerylist.filter;
      arr = arr.filter((item) => {
        return item.detail;
      });
      return arr;
    },
  },
  props: {
    NaturesList: {
      type: Array,
    },
  },
  methods: {
    // 顶部删除了一个标签
    delOne(del) {
      // 找到对应的分类
      let index = this.NaturesList.findIndex((item) => {
        return item.step == del.key;
      });
      if (index != -1) {
        // 该分类对应的组件
        let com = this.$refs.classifyItem[index];
        com.chooseArr = com.chooseArr.filter((item) => {
          return item != del.val;
        });

        com.changeChoose();
      }
    },

    // 重置
    reset(){
      this.$refs.classifyItem.forEach((item)=>{
        item.resetAndnonet()
      })
      this.$root.$children[0].$refs.activeCom.updatedPageData();
    }
  },
  components: { MyBreadcrumb, MyBreadcrumbItem, ClassifyItem, HasChoose },
};
</script>
<style lang='scss' scoped>

.TopSelector {
  .selector {
    margin: 0.75rem 0;
    // &:nth-last-child(1) {
    //   border-bottom: 1px solid var(--border-color-default);
    // }

    .choose {
      display: flex;
      flex-wrap: wrap;

      background-color: #dddddd;
      padding: 0.6rem 0;
      border-bottom: 1px solid white;
      .title {
        max-width: 10%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        span {
          padding: 0.875rem 0;
          font-size: 0.75rem;
          color: var(--text-color-default);
        }
        width: 100%;
        padding-left: 0.875rem;
      }

      .chooseitem {
        display: flex;
        max-width: 80%;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 100%;
        .haschoose {
          margin-right: 1.25rem;
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;
        }
      }

      .reset {
        max-width: 10%;
        box-sizing: border-box;
        width: 100%;
        font-size: 0.75rem;
        padding-right: 0.875rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          color: var(--red-color);
          cursor: pointer;
        }
      }
    }
  }
}
</style>