<template>
  <div class="ClassifyItem" ref="ClassifyItem" :class="{ isMore: !isMore }">
    <div class="name">
      <span>{{ ListItem.nature_name }}</span>
    </div>
    <div class="bg"></div>
    <ul class="list">
      <li class="nochoose">
        <span @click="nochoose" :class="{ actived: isNoChoose }">不限</span>
      </li>
      <li class="chooseList">
        <choose-item
          class="chooseitem"
          v-for="item in ListItem.values"
          :key="item.step"
          @changeChoose="changeChoose"
          :label="item.nature_value"
          :step="item.step"
          v-model="chooseArr"
        >
        </choose-item>
      </li>
    </ul>

    <div class="moreBtn">
      <span v-if="isshowMoreBtn && !isMore" @click="isMore = !isMore"
        >更多</span
      >
      <span v-if="isshowMoreBtn && isMore" @click="isMore = !isMore">收起</span>
    </div>
  </div>
</template>
<script>
import ChooseItem from "./classifyItemchild/ChooseItem";
export default {
  name: "ClassifyItem",
  data() {
    return {
      chooseArr: [],

      //是否点击更多
      isMore: false,

      //是否显示更多按钮
      isshowMoreBtn: false,
    };
  },

  methods: {
    isShowMoreBtn() {
      this.isshowMoreBtn =
        this.$refs.ClassifyItem.scrollHeight -
          this.$refs.ClassifyItem.clientHeight >
        5;
    },

    // 更新筛选信息
    updatedChoose() {
      this.$store.commit("updateGallerylist", {
        type: "changeClass",
        step: this.ListItem.step,
        chooseArr: this.chooseArr,
      });
      this.$store.commit("updateFilterDetil", this.createTopChoose());
      this.$store.commit("updatePageSort", {
        type: "page",
        val: 1,
      });

      // 修改底部分页页码
       this.$root.$children[0].$refs.activeCom.$refs.galleryMain.$refs.galleryMainRight.currentPage = 1
      
    },

    changeChoose() {
      setTimeout(() => {
        this.updatedChoose();
        this.$root.$children[0].$refs.activeCom.updatedPageData();
      });
    },

    // 重置数据，但不主动发起网络请求
    resetAndnonet() {
      this.chooseArr = [];
      this.$store.commit("updateGallerylist", {
        type: "changeClass",
        step: this.ListItem.step,
        chooseArr: this.chooseArr,
      });
      this.$store.commit("updatePageSort", {
        type: "page",
        val: 1,
      });
    },

    // 不限
    nochoose() {
      this.chooseArr = [];
      this.updatedChoose();
      this.$root.$children[0].$refs.activeCom.updatedPageData();
    },

    //创建一个对象用来描述顶部已选信息
    createTopChoose() {
      let obj = {};
      let chooseDetil = [];
      obj.nature_name = this.ListItem.nature_name;
      obj.step = this.ListItem.step;
      this.chooseArr.forEach((val) => {
        let Tem = {};
        this.ListItem.values.forEach((item) => {
          if (item.step == val) {
            Tem.step = item.step;
            Tem.nature_value = item.nature_value;
            chooseDetil.push(Tem);
          }
        });
      });

      obj.chooseDetil = chooseDetil;

      return obj;
    },

    //渲染选中状态，从vuex中取出数据赋值
    randerChoose() {
      let choose = this.$store.state.gallerylist.gallerylist.filter;
      let res = choose.find((item) => {
        return item.step == this.ListItem.step;
      });
      if (res) {
        this.chooseArr = res.chooseArr;
        this.$store.commit("updateFilterDetil", this.createTopChoose());
      }
    },
  },

  mounted() {
    this.isShowMoreBtn();
  },

  watch: {},
  props: {
    ListItem: {
      type: Object,
    },
  },
  created() {},
  computed: {
    isNoChoose() {
      return this.chooseArr.length == 0;
    },
  },

  components: {
    ChooseItem,
  },
};
</script>
<style lang='scss' scoped>

.isMore {
  height: 2.6rem !important;
}
.ClassifyItem {
  display: flex;
  border-bottom: 1px solid var(--border-color-default);
  align-items: flex-start;
  overflow: hidden;
  // height: 5rem;
  position: relative;
  .name {
    max-width: 10%;
    width: 100%;
    height: 2.6rem;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding-left: 0.875rem;
    display: flex;
    align-items: center;
    span {
      color: var(--text-color-default);
      font-size: 0.75rem;
      // font-weight: 600;
    }
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 10%;
    width: 100%;
    background-color: var(--bg-color-hint);
    z-index: 1;
  }

  .list {
    display: flex;
    width: 100%;
    max-width: 80%;

    li {
      list-style: none;
      font-size: 0.875rem;
      color: var(--text-color-minor);
      display: flex;
      align-items: center;
    }

    .chooseList {
      display: flex;
      flex-wrap: wrap;
      .chooseitem {
        margin-right: 0.5rem;
        height: 2.6rem;
        display: flex;
        align-items: center;
      }
    }

    .nochoose {
      margin-left: 0.2rem;
      flex: none;
      cursor: pointer;
      width: 3.5rem;
      height: 2.6rem;
      margin-right: 0.5rem;
      span {
        border-radius: 0.2rem;
        padding: 0.325rem 0.5rem;
      }
    }

    .actived {
      background-color: var(--red-color);
      color: white;
    }
  }

  .moreBtn {
    max-width: 10%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.875rem;
    align-items: center;
    height: 2.6rem;
    span {
      color: var(--text-color-minor);
      font-size: 0.75rem;
      cursor: pointer;
    }
  }
}
</style>