<template>
  <div class="GalleryMainRight">
    <div class="top">
      <sort></sort>
    </div>
    <div class="gooodList">
      <router-view :goodslist="goodslist" :paginated="paginated"></router-view>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <pagination
        :totalPage="paginated.pages == 0 ? 1 : paginated.pages"
        v-model="currentPage"
        @changePage="changePage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import Sort from "./GalleryMainRightChild/Sort.vue";
import Pagination from "common/pagination/Pagination";
export default {
  name: "GalleryMainRight",
  data() {
    return {
      currentPage: this.$store.state.gallerylist.gallerylist.page,
    };
  },
  created() {},
  props: {
    goodslist: {
      type: Array,
    },
    paginated: {
      type: Object,
    },
  },
  components: {
    Sort,
    Pagination,
  },
  methods: {
    changePage(page) {
      this.$store.commit("updatePageSort", {
        type: "page",
        val: page,
      });
      
      this.$root.$children[0].$refs.activeCom.updatedPageData();
      scrollTo(0,this.$root.$children[0].$refs.activeCom.getHeight())
    },
  },
};
</script>
<style lang='scss' scoped>


.GalleryMainRight {
  position: relative;
  // .gooodList {
  //   border-left: 1px solid #eee9f0;
  //   border-top: 1px solid #eee9f0;
  // }

  .pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>