<template>
  <div class="haschoose">
    <div class="title">{{ title }}:</div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.step">
        <div class="font">{{item.nature_value}}</div>
        <div class="del">
          <i class="iconfont icon-guanbi" @click="del(item.step)"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: null,
    step:null,
    list: {
      type: Array,
    },
  },
  created() {},
  methods:{

    del(itemStep){
      this.$emit('delOne',{
        key:this.step,
        val:itemStep
      })
    }
  
  }
};
</script>
<style lang='scss' scoped>

.haschoose {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
flex-wrap: wrap;
  margin-right: 0.3rem;
  .title {
    color: var(--text-color-minor);
    margin-right:0.5rem;
  }

  .list {
    display: flex;
      flex-wrap: wrap;
    .item {
        background-color: white;
      margin-right: 0.3rem;
      padding: 0.3rem 0.5rem;
      border-radius: 0.2rem;
      box-sizing: border-box;
      display: flex;
      border: 1px solid var(--border-color-default);
      .font {
        color: var(--red-color);
        margin-right: 1.5rem;
      }
      .del {
        i {
          color: var(--red-color);
        }

        width: 1.25rem;
        margin-left: -1.25rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>