<template>
  <div class="GalleryMain">
    <div class="container">
      <gallery-main-left class="left"></gallery-main-left>
      <gallery-main-right
      ref="galleryMainRight"
        class="right"
        :goodslist="goodslist"
        :paginated="paginated"
      ></gallery-main-right>
    </div>
  </div>
</template>
<script>
import { GalleryMainLeft, GalleryMainRight } from "./GalleryMainChild";
export default {
  name: "GalleryMain",
  data() {
    return {};
  },
  created() {},
  components: {
    GalleryMainLeft,
    GalleryMainRight,
  },
  methods: {
  
  },
  props: {
 goodslist:{
   type:Array
 },
 paginated:{
   type:Object
 }
  },
};
</script>
<style lang='scss' scoped>

.GalleryMain {
  .container {
    display: flex;
    .left {
      max-width: 13%;
      width: 100%;
    }
    .right {
      max-width: 87%;
      width: 100%;
    }
  }
}
</style>