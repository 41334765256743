<template>
  <div class="positive">
    <div class="box" @click="chooseOne" :class="{ active: active }">
      <span>{{ name }}</span>
      <i
        class="iconfont icon-jiantoushangsheng"
        v-show="!single && hasIcon && state[choose] == 'asc'"
      ></i>
      <i
        class="iconfont icon-jiantouxiajiang"
        v-show="!single && hasIcon && state[choose] == 'desc'"
      ></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: ["asc", "desc"],
      choose: 0,
    };
  },
  created() {
    let sort = this.$route.query.sort.split(" ")[1];
    if (sort) {
      this.choose = this.state.findIndex((item) => item == sort);
    }
  },
  methods: {
    chooseOne() {
      if (this.single) {
        this.$emit("chooseSort", null, this.index);
        return;
      }
      if (this.choose == 0) {
        this.choose = 1;
      } else {
        this.choose = 0;
      }
      this.$emit("chooseSort", this.state[this.choose], this.index);
    },
  },
  props: {
    name: {
      type: String,
    },

    //单一属性
    single: {
      type: Boolean,
      default: false,
    },

    //是否要图标
    hasIcon: {
      type: Boolean,
      default: true,
    },

    //当前的序号
    index: {
      type: Number,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='scss' scoped>
.positive {
  display: inline-block;
  border: 1px solid #e3e4e6;
  .box {
    padding: 0.2rem 0.75rem;
    cursor: pointer;
    span {
      color: #6c757d;
      font-size: 0.75rem;
    }

    i {
      color: #6c757d;
      font-size: 0.875rem;
    }
  }

  .active {
    span,
    i {
      color: #dc3545;
    }
  }
}
</style>