<template>
  <div class="gallerylist">
    <top-selector :NaturesList="NaturesList" ref="topSelector"></top-selector>
    <gallery-main
      ref="galleryMain"
      :paginated="paginated"
      :goodslist="goodslist"
    ></gallery-main>
  </div>
</template>
<script>
import GalleryMain from "./child/GalleryMain";
import TopSelector from "./child/TopSelector";
import Goods from "@/model/Goods.js";
export default {
  name: "GalleryList",
  data() {
    return {
      // 属性列表
      NaturesList: [],

      // 商品列表
      goodslist: [],
      //页码信息
      paginated: {},
    };
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.analysisPath();
      this.getNatures();
      this.updatedPageData();
    },
    // 解析路由获取数据
    analysisPath() {
      let cat_id, path, page, keyword, filter, sort;
      filter = [];
      let query = this.$route.query;
      cat_id = this.handObj("cat_id", query, "");
      path = this.handObj("path", query, "");
      sort = this.handObj("sort", query, "weigh asc");
      page = parseInt(this.handObj("page", query, 1));
      keyword = this.handObj("keyword", query, "");

      // 	1070000:1070001,1070002;1080000:1080001,1080002;
      if (query.path) {
        let _path = query.path.replace(/^;|;$/g, "");
        let arr = _path.split(";");

        arr.forEach((item) => {
          let obj = {};
          let _arr = item.split(":");
          obj.step = parseInt(_arr[0]);
          obj.chooseArr = [];
          _arr[1].split(",").forEach((value) => {
            obj.chooseArr.push(parseInt(value));
          });
          filter.push(obj);
        });
      }

      this.$store.commit("updateGallerylist", {
        data: { keyword, page, path, cat_id, sort, filter },
        type: "init",
      });
    },

    //获取当前组件距离顶部的距离，用户页码跳转时的置顶
    getHeight() {
      let headerH = document
        .querySelector("#app")
        .querySelector(".header").scrollHeight;
      return headerH;
    },

    // 改变路由
    changeUrl() {
      this.$router.replace({
        path: "/gallerylist/index/list",
        query: {
          path: this.$store.state.gallerylist.gallerylist.path,
          page: this.$store.state.gallerylist.gallerylist.page,
          cat_id: this.$store.state.gallerylist.gallerylist.cat_id,
          keyword: this.$store.state.gallerylist.gallerylist.keyword,
          sort: this.$store.state.gallerylist.gallerylist.sort,
        },
      });
    },

    // 对象处理函数
    handObj(key, obj, def) {
      if (obj[key]) {
        return obj[key];
      } else {
        return def;
      }
    },

    // 获取商品属性
    getNatures() {
      Goods.Goods.CategoryNatures({
        data: {
          cat_id: this.$store.state.gallerylist.gallerylist.cat_id,
        },
        succ: (res) => {
          this.NaturesList = res.list;
          setTimeout(() => {
            this.$refs.topSelector.$refs.classifyItem.forEach((item) => {
              item.randerChoose();
            });
          }, 100);
        },
        fail: (res, all) => {},
        
      });
    },

    updatedPageData() {
      this.changeUrl();
      this.getGoodList();
    },

    //获取商品列表
    getGoodList() {
      let loading;
      this.$nextTick(() => {
        loading = this.$loading(
          this.$refs.galleryMain.$refs.galleryMainRight.$el
        );
      });
      Goods.List.Lists({
        data: this.$store.state.gallerylist.gallerylist,
        succ: (res) => {
          this.goodslist = res.goodslist;
          this.paginated = res.paginated;
          loading.close();
        },
        fail: (res, all) => {
           loading.close();
          this.$message("error", all.msg);
        },
      });
    },
  },
  components: {
    GalleryMain,
    TopSelector,
  },
};
</script>
<style lang='scss' scoped>

.gallerylist {
  background-color: white;
  padding: 1.5rem 0;
}
</style>