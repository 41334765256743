<template>
  <div class="sort">
    <div class="btn">
      <div class="item" v-for="(item, index) in name" :key="item">
        <positive
          :name="item"
          @chooseSort="chooseSort"
          :single="index == 0 || index == 2"
          :index="index"
          :active="index == chooseindex"
        ></positive>
      </div>
    </div>
  </div>
</template>
<script>
import Positive from "common/positive/Positive";
export default {
  name: "Sort",
  data() {
    return {
      name: [this.$t('Common.Pages.Default'), this.$t('Home.Pages.Price'), this.$t('Product.Pages.SaleCount')],
      sort_en:['weigh','price','sell_count'],
      chooseindex: 0,
      sort: this.$store.state.gallerylist.gallerylist.sort,
    };
  },
  created() {
    this.chooseindex = this.sort_en.findIndex((item)=>item == this.$store.state.gallerylist.gallerylist.sort.split(' ')[0])
  },
  methods: {
    //修改排序方式
    chooseSort(sort, index) {
      this.chooseindex = index;
      //默认排序
      if (index == 0 && sort == null) {
        this.sort = "weigh asc";
      }
      //价格排序
      else if (index == 1) {
        if (sort == "asc") {
          this.sort = "price asc";
        } else if (sort == "desc") {
          this.sort = "price desc";
        }
      }
      //销量排序
      else if (index == 2) {
        this.sort = "sell_count desc";
      }

      this.$store.commit("updatePageSort", {
        type: "sort",
        val: this.sort,
      });
      this.$root.$children[0].$refs.activeCom.updatedPageData();
    },
  },

  components: {
    Positive,
  },
};
</script>
<style lang='scss' scoped>

.sort {
  background-color: var(--bg-color-hint);
  border: 1px solid var(--border-color-default);
  padding: 0.75rem 0.5rem;
  margin-bottom: 0.875rem;
  .btn {
    display: flex;
    .item {
      margin-right: 0.5rem;
    }
  }
}
</style>