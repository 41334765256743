<template>
<div class="GalleryMainLeft">
    <!-- 111 -->
</div>
</template>
<script>
export default {
    name:'GalleryMainLeft',
data(){
return {
}
},
created(){
},
}
</script>
<style lang='scss' scoped>

</style>