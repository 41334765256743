<template>
  <div class="chooseitem">
    <div class="itembox" :class="{ actived: actived }">
      <div class="name" @click="goChoose">
        <input
          class="myinput"
          type="checkbox"
          ref="myinput"
          :value="step"
          v-model="choose"
        />
        {{ label }}
      </div>
      <div class="del" v-if="actived" @click="del">
        <i class="iconfont icon-guanbi"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChooseItem",
  data() {
    return {};
  },
  computed: {
    choose: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    actived() {
      return this.value.includes(this.step);
    },
  },
  props: {
    label: null,
    value: null,
    step: null,
  },

  methods: {
    del() {
      this.$refs.myinput.click();
      // this.$emit('changeChoose
    },
    goChoose(){
       this.$emit('changeChoose')
    }
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.chooseitem {
  .itembox {
    padding: 0.325rem 0.6rem;
    display: flex;
    font-size: 0.875rem;
    color: var(--text-color-minor);
    align-items: center;
    .name {
      margin-right: 1.5rem;
      position: relative;

      .myinput {
        position: absolute;
        z-index: 2;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .del {
      width: 1.25rem;
      margin-left: -1.25rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .actived {
    background-color: var(--red-color);
    color: white;
    border-radius: 0.2rem;
    .myinput {
      width: 0 !important;
      height: 0 !important;
    }
  }
}
</style>